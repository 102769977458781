.Post
  &-list
    h2
      font-size size-3
      line-height 1
      padding 10px 0
      a
        font-weight 600
        font-family family-primary

  &-item
    border-bottom 0px !important

  &-footer
    float right
    font-weight 600
