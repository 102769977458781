@import 'nib'

body:not(.logged-in)
  .site-header
    &:not(.inverted)
      .header-logo-img
        display: none

      .site-header-right
        position: fixed
        box-shadow 0 1px 8px rgba(123,123,123,.8)
        width 100%
        z-index 10
        transition: all 0.5s ease-in-out;

        .site-navigation, .nav-assistive
          margin auto

        .site-navigation
          > .list-menu
            .item-menu
              height 60px

.image-logo
  margin 20px auto

.logo-text
  padding 10px
  h3
    font-size 26px
    span:first-child
      color green-light
    span:nth-child(2)
      color blue-light
    span:last-child
      color purple-light
      
  @media (min-width: responsive-breakpoint)
    padding 20px 10px 10px

.meeting-text
  position absolute
  bottom 1%
  text-align center
  left 65%
  h3
    font-size 8px
    color purple-light
    line-height 1.2
    @media (min-width: 800px)
      font-size 18px
      
.header-network
  display none

.site-header
  background-color white
  text-transform uppercase
  box-shadow 0 1px 8px rgba(123,123,123,.8)
  display flex
  flex-direction column

  &-right
    transition: all 0.5s ease-in-out;
    background-color white
    display flex
    padding-bottom 7px

  .Languages
    margin 5px

    li:first-child
      display none

    button
      color #7d7d7d !important

      &.Languages-selected
        color #4f39be !important

  @media (min-width: responsive-breakpoint)
    &-right
      padding-bottom 0px

    .Languages
      position absolute
      top 10px
      right 15px

.page-cannaworks-orlando
  .Languages
    position absolute
    top 10px
    right 5px
    li:first-child
      display none

    button
      color #bfbfbf !important
      background-color transparent
      text-shadow 1px 2px #000000
      -webkit-text-stroke-color black

      &.Languages-selected
        color white !important
        background-color transparent

.header-logo
  padding margin-gap margin-gap*2
  background-color white
  &-img
    background-image url("/public/assets/images/logo-header.png")
    background-repeat no-repeat
    background-position fixed
    height 60px
    width 288px
    display block

// COVER
// -----------------------------------------------------------
.site-cover
  height 60vw
  width 100%
  @extends $background-image
  background-size 100%
  background-position top center
  position relative
  z-index -1
  display flex
  flex-direction column
  justify-content center
  // Prevent image background to increase with width over 1000px
  @media (min-width: responsive-breakpoint)
    justify-content flex-end
    background-size cover
    min-height 400px
    
    &.small
      height 62vh
      
    &.medium
      height 75vh
    
    &.big
      height 90vh

.site-cover-colored
  width 100%
  height 23vw
  background #0e3559
  position relative
  z-index -1

// JUMBOTRON
// -----------------------------------------------------------
.header-jumbotron
  color white
  margin-left 20px
  margin-top 5%
  margin-bottom 30px

  @media (min-width: responsive-breakpoint)
    margin-bottom 80px

.jumbotron-title
  font-family family-pier-bold
  font-size 1.5rem
  line-height 1
  text-shadow 1px 1px #69707a

.jumbotron-description
  text-shadow 1px 1px #69707a

@media (min-width: 500px)
  .jumbotron-title
    font-size 2.3rem
@media (min-width: 767px)
  .header-jumbotron
    left 50px
  .jumbotron-title
    font-size 58px
  .jumbotron-description
    font-size size-3
@media (min-width: 1000px)
  .header-jumbotron
    bottom 25vh
  .jumbotron-title
    font-size 90px
  .jumbotron-description
    font-size size-3

.site-cover-colored
  display flex
  align-items center
  .header-jumbotron
    bottom 10px
    margin-top 0px
    margin-bottom 0px

.banner
  &-wrapper
    height: 100%
    width: 100%
    text-align center
    margin 0px
    position relative
    bottom 0px
    left: 0px

  &-title
    position absolute
    left: 0px
    right: 0px
    top 3vw
    color white
    font-size 3.5vw
    line-height 1
    padding 5px 10px 5px 15px
    margin auto

  &-description
    position: absolute
    bottom 5vw
    left 0px
    right 0px
    margin auto
    font-size 2.8vw
    color white
    padding 0px 10px 0px 15px

    p
      line-height 1.2

    a
      color white

  @media (min-width: responsive-breakpoint)
    &-wrapper
      margin 0px

    &-title
      padding 15px 15px 15px 20px
      font-size 3.5vw
      top 2vw

// LOGO
// -----------------------------------------------------------
.site-logo
  background-image url("/public/assets/images/logo.png")
  @extends $background-image
  width 310px
  height 236px
  display block
  margin 0 auto margin-gap*3.4

  &.staffing
    width 280px
    height 205px
    background-image url("/public/assets/images/logo-staffing.png")

  @media (min-width: 1000px)
    &.staffing
      width 315px
      height 230px
