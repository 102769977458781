.cookies
  width 100%
  position fixed
  background-color primary
  color white
  bottom 0px
  left 0px
  padding 10px
  z-index 10001
  box-shadow 0px 5px 10px 5px primary

  &.close
    opacity 0
    height 0
    padding 0
    visibility hidden
    transition 0.5s ease

  .wrapper
    > div:first-child
      p
        font-size 12px

      a
        color white
        text-decoration underline

    > div:last-child
      text-align center
      padding 10px 0

  @media (min-width: responsive-breakpoint)
    .wrapper
      width 100%
      max-width 1200px
      margin auto
      display: flex
      align-items center
      justify-content space-between

      > div:first-child
        p
          font-size 14px
