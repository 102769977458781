body:not(.home)
  .site-header-redes
    a
      color #4f39be

.site-header-redes
  display none

.site-navigation
  font-family pier-regular
  display none
  .link-menu span
  .cont-item-menu span
    color #4f39be

  .item-menu:not(:last-child)
    padding-right margin-gap

  .item-menu
    &:hover
      background-color transparent

  .list-menu
    .item-menu
      height: 80px;
      display: flex;
      align-items: center;

    .list-menu
      .item-menu
        height 40px

.nav-assistive
  color #4f39be
  margin-top 5px !important

@media (min-width: responsive-breakpoint)
  .site-navigation
    display inline-block
  .nav-assistive
    display none
  .site-header-redes
    display: flex;
    align-items center
    width: 150px;
    a
      color #4f39be
    .fa
      margin 0px 8px
      font-size 26px

// AUX NAVIGATION
// -------------------------------------------
#navaux
  z-index 13 !important

.nav-aux-container
  font-size 2rem
  text-transform uppercase
  .navigation-menu
    display block
  .navigation-item
    margin 26px
  .item-link,
  .item-link:hover
    color white

  .link-menu
    margin auto

    span
      word-break: break-all;
      font-size 20px

  .button--ghost
    background-color white

  .button
    margin auto
  .nav-menu .item-menu
    float none
    margin-bottom .7rem
    &:hover
      background-color transparent

  .list-menu
    .list-menu
      display none !important

body.page-candidatos

  .Languages
    button.Languages-selected
      color #5a2b72 !important
  .nav-assistive
    color purple

  .site-navigation
    .link-menu span
    .cont-item-menu span
      color purple-light

  .site-header-redes
    a
      color purple-light

.site-header
  padding 0 !important
  z-index 5

.item-menu
  border none !important

.link-menu
  span
    text-transform uppercase
    font-size 1rem !important