

// Execute brands
sprites($spritesheet_sprites);

// extra css
[class^="image-"],
[class*=" image-"]
  display inline-block
  vertical-align middle
