.footer-quick-contact
  color green
  font-size 20px
  p:not(:first-child)
    margin-top 2rem

.footer-about
  background-color primary
  color white
  padding margin-gap*2 0
  width 100%

  a {color: white}
  a:hover {color: purple}

  .wrap
    padding 20px
    margin 0 auto
    max-width 1200px
    display flex
    flex-direction column
    align-items center

  h4
    font-size 1.1em

  .footer-social
    a
      margin 5px 5px
      i
        font-size 48px

  @media (min-width: 680px)
    .wrap
      display flex
      justify-content center
      flex-direction column
      align-items center
      padding 0
    .message
      margin-bottom 10px

    h4
      font-size 1.25em

    .footer-social
      a
        margin 0 12px

.footer-form
  max-width 600px
  margin margin-gap*2 auto 0
  input
    width 480px
  .button
    flex 1
    border-radius 0

// COLOPHON
// ------------------------------------
.site-colophon
  padding margin-gap
  background-color #0f3558
  color white
  position: relative
  padding-bottom 125px

  a
    color white

  h4
    margin-bottom 5px

  .links
    .terms
      padding 10px
      color white

      &:hover
        text-decoration underline
        color white

    .contact-modal
      padding-left 10px

      &:hover
        text-decoration underline
        color white

  .social-networks
    padding 5px

    a
      margin-right 5px

  .login
    margin-top 5px

    a
      color white

      &:hover
        color white

  @media (min-width: 350px)
    padding-bottom 90px
    
  @media (min-width: 580px)
    padding-bottom 55px
    
  @media (min-width: responsive-breakpoint)
    h4
      margin-bottom 0px

    p
      text-align: left

    .links
      display flex

      .terms
        padding 0px
        margin-left 12px

    .social-networks
      padding 0px 0px 0px 10px

body.page-candidatos
  .footer-about
    background-color rgba(purple-light, 85%)

  .site-colophon
    background-color purple-light

.contact-wrapper
  background-color #426488

  .information
    text-align center
    max-width 650px
    margin auto
    padding 40px
    color white
    font-size 22px

    .tel
      color white
      font-size 24px

  @media (min-width: responsive-breakpoint)
    .information
      font-size 24px

      .tel
        font-size 28px

.Social
  display flex
  justify-content center
