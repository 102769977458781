.popup-master
  background-image url("/public/assets/images/cannaworks-sept-2020.jpeg")
  background-position 0
  background-repeat no-repeat
  background-size cover
  position relative
  width 320px
  height 145px

  &-text
    position absolute
    bottom 6px
    padding 5px 20px
    max-width 270px
    left 0px
    right 0px
    margin auto

    h3
      font-size 12px
      line-height 1.2
      font-family pier-bold
      color white

  @media (min-width: 360px)
    width 350px
    height 160px

  @media (min-width: 800px)
    width 700px
    height 320px
    left 0px

    &-text
      padding 20px
      bottom 15px
      max-width 480px
      h3
        font-size 20px


