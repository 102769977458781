.popup-2023
  width: 320px;
  height: 802px;
  background-image url('https://prmedcannbiz.com/assets/images/prmedcann-convention-mobile-2023.png')
  background-size: contain;
  background-repeat: no-repeat;
  position relative
  
  .nomina
    position absolute
    top: 724px;
    left: 136px;
    width: 148px;
    height: 24px;
    
  .green-carpet
    position absolute
    top: 767px;
    left: 136px;
    width: 148px;
    height: 24px;
    
  .concert
    position absolute
    top: 530px;
    left: 62px;
    width: 178px;
    height: 27px;
    
  .certificate
    position absolute
    top: 98px;
    left: 147px;
    width: 129px;
    height: 34px;

  @media (min-width: 1024px)
    background-image url('https://prmedcannbiz.com/assets/images/prmedcann-convention-2023.jpeg')
    width: 900px;
    height: 600px;
    
    .nomina
      width: 218px;
      height: 60px;
      top: 395px;
      left: 24px;

    .green-carpet
      width: 218px;
      height: 60px;
      top: 500px;
      left: 24px;
      
    .concert
      width: 250px;
      height: 37px;
      top: 435px;
      left: 322px;
      
    .certificate
      width: 218px;
      height: 60px;
      top: 500px;
      left: 660px;