
#backtotop
  z-index 999
  display inline-block
  position fixed
  visibility hidden
  bottom 30px
  right 20px
  width 36px
  height 36px
  line-height 36px
  font-size 16px
  text-align center
  opacity .5
  color  white
  background-color primary

  i
    display block
    width 100%
    height 100%
    line-height inherit

  &.visible
    visibility visible
    opacity .7

  &:hover
    opacity 1
