.newsletter
  display flex
  align-items center
  width 320px
  height 760px
  padding-top 5px
  position: relative

  .wrapper
    width 100%

    > div:first-child
      text-align left
      display flex
      align-items center
      justify-content center

      h2
        color white
        z-index 2
        line-height 1.1
        position relative
        font-weight 500
        font-size 13px

      p
        color white
        font-size 11px

      .first
        min-height 260px
        background-position top
        background-repeat no-repeat
        background-size cover
        position relative
        display flex
        align-items center
        width 100%
        padding 10px

      .first
        h2
          margin 0px 0px 15px

      .button
        height: 38px;
        line-height: 18px;

    > div:last-child
      background-color white
      padding 5px

      img
        display none

      h3
        font-size 1em
        color green
        font-weight 500
        padding 10px 0px 0px
        line-height 1
        
      p
        font-size 15px
        line-height 1
        color #363d74

  .cognito
    padding 0 10px
    margin-top 0px !important

    iframe
      margin-top 10px

    .c-field
      padding 3px 0

    .c-button
      font-weight bold !important

  @media (min-width: 1024px)
    width 900px
    height 520px

    .wrapper
      display flex
      align-items center
      justify-content center

      > div:first-child
        width 67%
        height 480px
        display initial

        h2
          font-size 26px
          text-align left

        .first
          height 100%
          padding 30px 20px 10px 40px
          width initial
          min-height initial

        .first
          h2
            margin 0px 0px 25px

        .button
          height 45px
          line-height 24px

      > div:last-child
        width 33%
        height 480px
        padding 10px 5px 5px 5px

        img
          padding 5px
          margin-top 10px
          display initial
          height 110px !important

        h3
          font-size 15px
          padding 0px
          
        p
          font-size 15px

    .cognito.c-span-24.c-span-6
      width 50% !important
