.Popup-Father
    margin 5% auto
    border-radius 10px
    width 90% !important
    background-color #ffffff !important
    .Popup-container
        display flex
        align-items center
        border-radius 10px
        flex-direction column
        background-image url("/public/assets/images/Popup-fondo2.png")
        background-size 100% 100%
        .Popup-body
            display flex
            flex-direction column
            align-items center
            width 100%
            position relative
            padding 35px 20px 5px
            .Popup-logo
                width 13em
                height 10em
                margin-bottom 10px
                img
                    width 100% !important
                    height 100% !important
            .animacion
                display none
            .Popup-content
                width 100%
                display flex
                flex-direction column
                .Popup-text
                    width 100%
                    justify-content center
                    z-index 1
                    h2
                        color #fff
                    h3 
                        color #FC9C47
                .Popup-form
                    display flex !important
                    width 100%
                    iframe
                        min-height 300px
                    .cog-form__container
                        background-color transparent
        .Popup-divider
            width 100%
            height 1em
            background-color #fff
        .Popup-footer
            display flex
            align-items center
            flex-direction column
            justify-content space-evenly
            width 100%
            position relative
            padding 15px
            background-size 100% 100%
            background-repeat no-repeat
            background-image url("/public/assets/images/Popup-footer2.png")
            a
                background-color #920D33
                height 4em
                border-radius 30px
                padding 10px
                p
                    font-size 1.3em
               
            p
                width auto
                word-wrap break-word
                color #fff
                text-transform uppercase
            .Popup-tel
                font-size 2em
                display flex
                align-items center
                justify-content center
                img 
                    height 1.2em !important
            .Popup-footer-left
                display flex
                justify-content center
                align-items center
                margin-bottom 10px
                .canna-ico1
                    background-size contain
                    background-repeat no-repeat
                    background-image url("/public/assets/images/Popup-icon1.png")
                    width 100px
                    height 80px
                p
                    width 70%
                    line-height 1.2
                    text-align left

            .Popup-button
                margin-bottom 10px
                line-height 1.2
                justify-content center
            .Popup-moreinfo
                font-weight 100
        .final-line
            font-size 0.7em
            margin-top 10px

    @media (min-width: 250px)
        .Popup-container
            .Popup-body
                .Popup-content
                    .Popup-text
                        h2
                            font-size 1.5em
                            line-height 1.1
                            margin-bottom 10px
                        h3
                            font-size 1.2em
                            line-height 1.1
            .Popup-footer
                .Popup-footer-left
                    width 100%
                    .canna-ico1
                        width 6em
                        height 4em
                    .canna-ico1.mid
                        width 6em
                        height 5em
                    p
                        font-size 0.9em
                a 
                    display flex
                    align-items center
                    p
                        font-size 0.9em
                .Popup-tel
                    font-size 1.5em

    @media (min-width: 400px)
        .Popup-container
            .Popup-body
                .Popup-content
                    .Popup-text
                        h2
                            font-size 1.8em
                        h3
                            font-size 1.2em
            .Popup-footer
                .Popup-footer-left
                    width auto
                    .canna-ico1
                        width 6em
                         height 5em
                    p
                        font-size 0.9em
                a 
                    display flex
                    align-items center
                    p
                        font-size 1em
                .Popup-tel
                    font-size 1.5em

    @media (min-width: 1024px)
        width 100% !important
        overflow hidden
        height auto
        margin auto
        .Popup-container, .Popup-body
            display flex
            flex-direction column
            align-items center
        .Popup-container
            height auto
            background-image url("/public/assets/images/Popup-fondo1.png")
            .Popup-body
                .animacion
                    display flex
                    width 18em
                    height 40em
                    position absolute
                    left 0px
                    background-size 100%
                    background-repeat no-repeat
                    background-image url("/public/assets/images/palmera.gif")
                .Popup-content
                    flex-direction row
                    max-width 735px

                    .Popup-text
                        width 50%
                        text-align right
                        padding 15px 15px 0px 0px
                        h2
                            font-size 2.2em
                            span
                                display block
                        h3
                            font-size 1.6em

                    .Popup-form
                        width 50%
                        iframe
                            min-height 280px
            .Popup-footer
                flex-direction row
                height 7em
                background-image url("/public/assets/images/Popup-footer1.png")
                a
                    width 13em
                    height auto
                    p
                        font-size unset
                p
                    width 13em
                .Popup-footer-left
                    justify-content space-evenly
                    margin-bottom 0px
                    p
                        width 56%
                    .canna-ico1
                        width 5em
                        height 4em
                        
                .Popup-button
                    margin-bottom 0px
                .Popup-button.mid
                    width 20em
                .Popup-tel
                    font-size unset
                .final-line
                    position absolute
                    bottom 3px
            .Popup-footer.mid
                height 8em