.eb-content
  position relative
  min-height 200px
  margin-top 20px

  &:before
  &:after
    position absolute
    left 50%
    z-index -1

  &:before
    width 32px
    height @width
    transform translate(-(@width / 2), 0)
    content ''
    top 0
    background url("/public/assets/images/loader.gif") repeat0 0 no-repeat transparent
    display block

  &:after
    color primary
    top 48px
    transform translate(-137px, 0)
    content 'Cargando tickets, por favor espere...'
