
@import 'nib'
@import '_reset'
@import 'layout/images'
@import '_images-conf'

$background-image
  background-size cover
  background-position center center
  background-repeat no-repeat

$absolutize
  position absolute
  top 0
  right 0
  left 0
  bottom 0

.background-image
  @extends $background-image

.text-center
  display flex
  align-items center
  flex-direction column
  text-align center
  
.text-underline
  text-decoration underline

body:not(.logged-in)
  .background-image--opaqued
    position relative
    z-index 0
    &:before
      @extends $absolutize
      content ''
      z-index -1
      display block
      background-color rgba(black, .35)

  .background-image
    
    &--purple
      position relative
      z-index 0
      &:before
        @extends $absolutize
        content ''
        z-index -2
        display block
        background-color rgba(#702e85, .95)

    &--gradient-green
      &:after
        @extends $absolutize
        content ''
        z-index -1
        display block
        background: linear-gradient(to top, rgba(#46833d, 0.7) 5%, transparent 25%);

    &--gradient-line-orange
      &:after
        position absolute
        bottom 0
        left 0
        width 100%
        height 3%
        content ''
        z-index -1
        display block
        background: linear-gradient(to right, #ff8201 0%, transparent 100%);

    &--gradient-line-green
      &:after
        position absolute
        bottom 0
        left 0
        width 100%
        height 3%
        content ''
        z-index -1
        display block
        background: linear-gradient(to right, #46833d 0%, transparent 100%);
