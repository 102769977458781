.popup-florida
  background-image url("/public/assets/images/cannaworks-cannabis-florida.jpg")
  background-position center
  background-repeat no-repeat
  background-size cover
  position relative
  width 320px
  height 225px

  .text
    position absolute
    top 23%
    text-align right
    padding 5px 20px
    max-width 600px
    left 0px
    right 0px
    margin auto

    h3
      font-size 12px
      line-height 1.2
      font-family pier-bold

  @media (min-width: 360px)
    width 350px
    height 255px

  @media (min-width: 800px)
    width 700px
    height 524px
    left 0px

    .text
      padding 20px
      h3
        font-size 24px

.popup-cupon
  background-color white
  color grey-darker
  padding: 3px

  .wrapper
    border 2px dashed grey-darker
    padding: 3px

  p
    font-size 10px
    margin-bottom 2px

  @media (min-width: 800px)
    padding: 5px

    .wrapper
      padding: 5px
    p
      font-size 16px
      margin-bottom 5px

.wrapper
  &-florida
    max-width 100%
    top 100%
    justify-content space-evenly
    display flex
    width 320px
    a
      color inherit

  &-form
    max-width 100%
    top 100%
    min-height 10px!important
    justify-content space-evenly
    display flex
    width 320px
    a
      color inherit

  @media (min-width: 360px)
    &-florida
      width 350px
    &-form
      width 350px

  @media (min-width: 800px)
    &-florida
      width 700px
    &-form
      width 700px


.banner
  &-florida
    font-size 10px
    color white !important
    align-items center
    padding 5px 0px 5px 0px

    a
      text-decoration none

    &:first-child
      background-color #773090
      width 50%
      line-height 1

    &:nth-child(2)
      background-color #608351
      width 50%
      line-height 1

  &-form
    font-size 10px
    color white !important
    align-items center
    padding 5px 0px 5px 0px

    a
      text-decoration none

    &:first-child
      background-color #608351
      width 50%
      line-height 1

    &:nth-child(2)
      background-color #773090
      width 50%
      line-height 1

  @media (min-width: 360px)
    &-florida
      font-size 10px
      padding 5px 0px 5px 0px
    &-form
      font-size 10px
      padding 5px 0px 5px 0px


  @media (min-width: 800px)
    &-florida
      font-size 19px
      padding 10px 0px 10px 0px
    &-form
      font-size 19px
      padding 10px 0px 10px 0px

