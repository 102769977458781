.Container
  margin auto

  &-max
    max-width 1280px
    margin auto
    padding 10px

  &.container--blue-dark
    position: initial !important
    margin-top 0px !important

  &--video
    position relative
    height: 59vw

.mce-container.mce-panel.mce-floatpanel.mce-window.mce-in
  width 680px !important

  .mce-container-body.mce-abs-layout
    width 630px
    overflow visible

.Content
  padding 10px 8px 16px // Para separar grid-items
  &.is-medium
    font-size: size-5
    code
      font-size: size-6
  &.is-large
    font-size: size-4
    code
      font-size: size-5
  &:not(:last-child)
    margin-bottom: 20px
  p
    color inherit
  h1,
  h2
    font-family family-pier-bold

  h3,
  h4,
  h5,
  h6
    font-family family-gotham

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    color: text-strong
    font-weight: 300
    line-height: 1.125
    margin-bottom: 20px
  h1,
  h2,
  h3
    &:not(:first-child)
      margin-top: 40px
  h1
    font-size: 1.6em
  h2
    font-size: 1.4em
  h3
    font-size: 1.2em
  h4
    font-size: 1.1em
  h5
    font-size: 1em

  @media (min-width: responsive-breakpoint)
    h1
      font-size 2em !important
    h2
      font-size 1.75em !important
    h3
      font-size 1.5em !important
    h4
      font-size 1.25em !important
    h5
      font-size 1.125em !important
    h6
      font-size 1em !important

  p:not(:last-child)
    margin-bottom: 1em
  li + li
    margin-top: 0.25em
  ol
    list-style: decimal outside
    margin: 1em 2em
  ul
    list-style: disc outside
    margin: 1em 2em
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square
  blockquote
    background: background
    border-left: 5px solid border
    padding: 1.5em
    &:not(:last-child)
      margin-bottom: 1em

.Message--success
  color #48762b !important

.table--purple,
.table--purple td,
.table--purple th
  border-collapse collapse
  border 2px solid purple
.table--purple td,
.table--purple th
  padding margin-gap*1.5 margin-gap

.candidates-form
  display: flex
  width: 100%
  justify-content: center
  flex-flow: row wrap

.candidates-form form
  max-width: 650px
  width: 90%

.candidates-form button
  width: 200px
  left: 50%
  margin-left: -100px

.candidates-link
  position: fixed
  bottom: 0
  left: 50%
  width: 250px
  background-color: purple
  color: white
  padding: margin-gap
  text-align: center
  margin-left: -125px
  z-index: 9999
  border-radius: 5px 5px 0 0

.candidate-wrap
  width: 100%
  display: flex
  flex-flow: row wrap
  padding: margin-gap margin-gap * 2
  justify-content: flex-start
  align-items: center

.candidate-wrap h2
  margin-right: margin-gap
  color: purple
  font-size: 30px
.candidate-wrap button
  margin-top: margin-gap
  margin-right: margin-gap
  width: 115px
.table--candidates thead tr
  background-color: purple
  color: white
  border-right: 2px solid purple
.table--candidates thead td,
.table--candidates tbody td
  padding: margin-gap
  vertical-align: middle
  min-width: 100px
.table--candidates tbody td
  border-collapse collapse
  border 2px solid grey-lighter
.table--candidates thead td
  text-align: center

.agenda-date
  font-size 12px
  text-align center
  line-height 1
  div:nth-child(2)
    font-size size-3
    letter-spacing 3px

.table--centered
  td
    vertical-align middle
    text-align center

@media all and (max-width: 1600px)
  .table--candidates
    display: block
    overflow-x: scroll

.table--agenda
  @extends .table--centered
  tr > td:first-child
    @extends .agenda-date

.ImageList
  margin-bottom 0px

.image--background
  width 100%
  background-repeat no-repeat
  background-position 50% 20%
  background-size cover
  height 30vw

  &.small
    height 37vw
    min-height 150px

  @media (min-width: responsive-breakpoint)

    &.small
      height 13vw
      min-height 250px

.back-button
  z-index 10
  position absolute
  padding 5px
  color white !important
  cursor pointer
  display flex
  align-items center
  i
    font-size 30px
    margin-right 5px

.video-wrapper
  width 50%
  text-align center

  .embed-container
    position relative
    padding-bottom 56.25%
    height 0
    overflow hidden

    iframe
      position absolute
      width 80%
      height 80%
      top 0
      left 0
      right 0
      bottom 0
      margin auto

  .video-wrapper:last-child
    width 100%

  @media (min-width: responsive-breakpoint)
    &:last-child
      margin auto

.background
  background-size cover
  background-repeat no-repeat

  &-video
    position absolute
    left 0
    top 0
    min-width 100%
    height: 57vw
