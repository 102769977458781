@import 'nib'

.testimonial-list
  width 100%
  text-align center

.testimonial-item
  width 100%
  color white
  > .wrapper
    height 650px
    position relative

  &.courses
    position relative
    .selects-all

      .select
        margin: 15px 0px 20px 0px
        min-width 230px

        select
          min-width 230px

    .description
      position absolute
      top 96%
      left 0
      right 0

      &.window
        top 0px
        bottom 200px
        width 98%
        display flex
        align-items center
        justify-content center
        padding 10px
        background-color rgba(purple, 95%)
        border-radius 10px
        margin auto
        min-height 150px

        a:not(.button)
          color white

    .testimonial-resume
      strong
        color white
        
    .new-course
      position absolute
      top 10px
      right 10px
      width 85px
      height 66px
      background-repeat no-repeat
      background-size contain
      display flex
      align-items center
      justify-content center
      text-align center
      z-index 1
      
      p
        color white
        font-weight bold
        transform rotate(18deg)

  @media (min-width: responsive-breakpoint)
    &.courses
      .selects-all
        display flex
        flex-direction initial
        align-items center
        justify-content center

        .select:first-child
          margin-right 5px

      .description
        &.window
          bottom 170px

.testimonial-image,
.testimonial-image:after
  @extends $absolutize

.testimonial-image
  background-size: cover;
  background-position: 50% 0;
  z-index 1
  &:after
    content ''
    transition background-color 200ms ease-in-out
    background-color rgba(black, .25)

.testimonial-content
  position relative
  z-index 2
  padding 20px 10px

  .event-item
    display none

  .event-item.active
    display table-row !important

.testimonial-title
  font-size size-5
  text-transform uppercase
  letter-spacing 2px

.testimonial-resume
  font-size 12px
  font-weight 300

.both-events
  position: absolute;
  z-index: 5;
  top 94%
  text-align center
  a
    margin-bottom 10px

@media (max-width: responsive-breakpoint)
  .select-visible
    display none !important
    &:after
      content ""

// RESPONSIVE
// ---------------------------------------------------
@media (min-width: 450px)
  .testimonial-resume
    font-size 14px

@media (min-width: responsive-breakpoint)
  .testimonial-content
    padding 20px 150px

  .testimonial-resume
    display none

  .testimonial-title
    font-size 19px
    letter-spacing 2px
    transition transform 350ms ease-out
    transform translate3d(0, 10px, 0)
  .testimonial-item
    > .wrapper
      height 400px

    &.courses
      &:last-child:nth-child(odd)
        width 100% !important

      > .wrapper
        height 620px

      .table
        max-width: 450px;
        margin: 0px auto;
        color: #ffffff;

        tr
          height 88px

  .testimonial-item:hover
    .testimonial-image:after
      background-color rgba(black, .5)
    .testimonial-resume
      display block
    .select-visible
      display none !important
      &:after
        content ""
    .testimonial-title
      transform translate3d(0,-8px,0)

@media (min-width: 1090px)
  .testimonial-item
    width 50% !important

  .both-events
    position: absolute;
    z-index: 5;
    bottom: 5%;
    top initial
    right: -300px;
    width 615px

    p
      max-width 350px
      margin 0 auto
