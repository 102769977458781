.Popup
  max-width 925px !important
  padding 2px !important
  text-align center
  table
    max-width 550px
    margin 0 auto
    tr
      line-height 1
      border-bottom 7px solid transparent
      td:nth-child(2)
        min-width 55px

.popupS
  &-open
    background-color transparent !important
    box-shadow none !important
    margin 0px !important
    
  &-close
    color white

  &-content
    background-color transparent

  &-layer
    background-color transparent !important

  @media (min-width: 800px)
    &-content
      img
        height 90vh !important
        max-height 980px
