#prom-roulette
    margin 5% auto
    border-radius 10px
    width 90% !important
    background-color #ffffff !important

    .prom-roulette-container
        display flex
        align-items center
        flex-direction column

    .prom-roulette-app
        display flex
        width 100%
        align-items center
        justify-content center
        background-size cover
        border-radius 10px 0 0 10px
        background-image url("/public/assets/images/wallpaper.jpg")

    .prom-roulette-logo
        z-index 9
        width 35px
        height 35px
        position absolute
        border-radius 100px
        background-color #eee
        background-size cover
        background-image url("/public/assets/images/logo-header-2.png")

    .prom-roulette-form
        padding 20px 30px
        width 100%

    .prom-roulette-image
        padding 20px 30px

    .prom-roulette-description
        text-align: justify;
    
    .prom-roulette-cognite-form
        margin 20px 0
        display none

        .cog-label
            display none

        input
            margin 0px

    img
        height auto !important

    @media (min-width: 1024px)
        width 100% !important
        overflow hidden
        height 600px
        margin auto

        .prom-roulette-app
            height 600px

        .prom-roulette-container
            flex-direction row
            align-items flex-start

