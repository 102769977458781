@keyframes modal-fade-in
  from
    opacity 0
    transform translate3d(0,0,0) scale(1.5)
  to
    transform scale(1)
    opacity 1

.modal:not(.fade)
  fixed top 0 bottom 0
  right 0
  left 0
  background-color rgba(blue, 85%)
  color white
  z-index 12
  display none
  overflow auto
  > .wrapper
    absolute
    top 0
    bottom 0
    left 0
    right 0
    max-width 1100px
    margin 0 auto
    overflow auto

  .modal-content
    background-color initial
    border: 0px;
    box-shadow: none;
  h1
    font-size 2em
    margin-bottom 10px
  h3, h4
    color white
  a
    color white
    &:hover
      text-decoration underline
  .close
    color white
    background none
    absolute
    right 4%
    top 20px
    border none
    z-index 14
    position absolute
    &:hover
      color white
  &.in
    display block
    animation modal-fade-in 70ms ease-in
  &.out
    display none

  input[type=number]
    max-width inherit
    margin-left 0
    text-align left

  .form-field
    margin 0 auto 7px !important

  .Message--success
    margin-bottom 20px !important

  &.modal--white
    background-color rgba(white, 0.9)
    .close
      color blue
    .condiciones
      p
        color grey-dark
        font-size size-7

      a
        color grey-dark
        text-decoration: underline

        &:hover
          color rgba(grey-dark, 90%)

  .modal-blue
    color blue
    p, a
      color blue

body.page-candidatos
  .modal
    background-color rgba(purple-light, 85%)
    color white


// Block body scroll
html.modal-opened,
.modal-opened body
  overflow hidden
  -webkit-overflow-scrolling touch
