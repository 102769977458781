
.book-fixed
  position fixed
  top 120px
  right -34px
  width 80px
  height 80px
  color white
  background-color primary
  text-align center
  z-index 2
  padding 5px
  display flex
  align-items center
  border-radius 50%
  transition all 70ms ease-in
  cursor pointer
  box-shadow 0px 1px 4px 0px grey-dark

  a
    color white

    &:visited, &:hover
      color white

  .fa
    font-size 30px
    padding 8px

  @media (min-width: responsive-breakpoint)
    display: none
    right -38px
    height 90px
    width 90px
    top 32%

    .fa
      font-size 32px
      padding 10px
