@font-face {
    font-family: "gotham-bold";
    src: url("/public/assets/fonts/gotham/GothamRnd-Bold.otf");
}

@font-face {
    font-family: "gotham-book";
    src: url("/public/assets/fonts/gotham/GothamRnd-Book.otf");
}

@font-face {
    font-family: "gotham-light";
    src: url("/public/assets/fonts/gotham/GothamRnd-Light.otf");
}

@font-face {
    font-family: "gotham-medium";
    src: url("/public/assets/fonts/gotham/GothamRnd-Medium.otf");
}

@font-face {
    font-family: "moon-bold";
    src: url("/public/assets/fonts/moon/Moon-Bold.otf");
}

@font-face {
    font-family: "moon-light";
    src: url("/public/assets/fonts/moon/Moon-Light.otf");
}

@font-face {
    font-family: "pier-bold-italic";
    src: url("/public/assets/fonts/pier/pier-bold-italic.otf");
}

@font-face {
    font-family: "pier-bold";
    src: url("/public/assets/fonts/pier/pier-bold.otf");
}

@font-face {
    font-family: "pier-italic";
    src: url("/public/assets/fonts/pier/pier-italic.otf");
}

@font-face {
    font-family: "pier-regular";
    src: url("/public/assets/fonts/pier/pier-regular.otf");
}
